import type { LocationQuery, LocationQueryValue } from "vue-router"
import get from "lodash-es/get"
import isEqual from "lodash-es/isEqual"
import omit from "lodash-es/omit"

const queryParams = ref<LocationQuery>({})

const decodeComponent = (value: string) => {
  const decodedValue = decodeURIComponent(value)

  try {
    return JSON.parse(decodedValue)
  } catch {
    return decodedValue
  }
}

const decodeQueryParam = (value: LocationQueryValue | Array<LocationQueryValue>, forceArray = false) => {
  if (Array.isArray(value)) {
    return value.map((v) => decodeComponent(v?.toString() ?? "null"))
  } else {
    return forceArray ? [decodeComponent(value?.toString() ?? "null")] : decodeComponent(value?.toString() ?? "null")
  }
}

const encodeComponent = <T = unknown>(value: T) => {
  if (typeof value === "string") {
    return encodeURIComponent(value)
  } else {
    return encodeURIComponent(JSON.stringify(value))
  }
}

const encodeQueryParam = <T = unknown>(value: T) => {
  if (Array.isArray(value)) {
    return value.map((v) => encodeComponent(v))
  } else {
    return encodeComponent(value)
  }
}

export function useQueryParam<T>(parameterName: string, defaultValue: T | null = null, forceArray = false) {
  const route = useRoute()
  const router = useRouter()

  const value = ref<T | null>(route.query[parameterName] ? decodeQueryParam(route.query[parameterName], forceArray) : defaultValue)

  watch(
    value,
    (newValue, oldValue) => {
      if (isEqual(newValue, oldValue)) {
        return
      }

      if (newValue === null) {
        queryParams.value = omit(queryParams.value, parameterName)
        router.replace({ query: omit(route.query, parameterName) })
      } else {
        queryParams.value[parameterName] = encodeQueryParam(newValue)
        router.replace({ query: { ...route.query, [parameterName]: queryParams.value[parameterName] } })
      }
    },
    { deep: true, immediate: true },
  )

  watch(
    () => get(route.query, parameterName),
    (newValue, oldValue) => {
      if (isEqual(newValue, oldValue)) {
        return
      }

      value.value = route.query[parameterName] ? decodeQueryParam(route.query[parameterName], forceArray) : null
    },
    { deep: true },
  )

  return {
    value,
  }
}
